export const CheckIcon = (props) => (
    <svg
        viewBox="0 0 24 24"
        width={props.width ?? "120px"}
        height={props.height}
        fill="#ff9418"
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        {...props}
    >
        <path d="M21 6.285 9.84 19.018 3 13l1.319-1.49 5.341 4.686L19.525 5 21 6.285z" />
    </svg>
);