import styles from "./MapSection.module.scss";
import { Fragment, useEffect, useState, forwardRef } from "react";
import cx from "classnames";
import axios from "axios";
import moment from "moment";
import "moment/locale/ru";
import sortBy from "lodash.sortby";
import dayjs from "dayjs";
import ReactInputMask from "react-input-mask";
import {
  Modal,
  Button,
  Form,
  Input,
  DatePicker,
  InputNumber,
  Popover,
  notification,
} from "antd";
import { WarningTwoTone } from "@ant-design/icons";
moment.locale("ru");
const dateFormat = "YYYY-MM-DD HH:mm:ss";
const viewFormat = "DD.MM HH:mm";

const InputMask = forwardRef((props, ref) => {
  return (
    <ReactInputMask {...props}>
      {(inputProps) => (
        <Input
          {...inputProps}
          ref={ref}
          disabled={props.disabled ? props.disabled : null}
        />
      )}
    </ReactInputMask>
  );
});

const MapSection = () => {
  // array of hosts
  const [hostGroups, setHostGroups] = useState();
  const [reserved, setReserved] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await axios.get(
          "http://store.alexkl43.beget.tech/hosts"
        );
        setHostGroups(data);
      } catch (err) {
        console.log("err", err);
      }
    };
    getData();
  }, []);

  const onFinish = async (values) => {
    const toSend = {
      hosts: [reserved.id],
      phone: `7${values.phone.replace(/[(|)|\-| ]/gi, "")}`,
      from: values.dateFrom.format(dateFormat),
      to: values.dateFrom.add(values.hours, "hour").format(dateFormat),
    };
    setLoading(true);

    try {
      await axios.post("http://store.alexkl43.beget.tech/book", toSend);
      setLoading(false);
      notification.success({
        description: "Компьютер успешно забронирован",
      });
      setReserved();
    } catch (err) {
      notification.error({
        description:
          (err.response.data && err.response.data.message) || err.message,
      });
      setLoading(false);
    }
  };

  const popoverContent = reserved && (
    <div
      style={{ display: "grid", gridTemplateColumns: "repeat(1, 1fr)", gap: 8 }}
    >
      {reserved.bookings.map(({ from, to }) => (
        <span>
          {`С ${moment(from).format(viewFormat)} до ${moment(to).format(
            viewFormat
          )}`}
        </span>
      ))}
    </div>
  );

  const sortOpts = ["alias.length", "alias"];

  return (
    <section className={styles.computers} id="reservation">
      <h2 className={styles.computersHeader}>Бронирование</h2>
      {hostGroups && (
        <div className={styles.computersGroup}>
          {hostGroups.map(({ id, title, hosts }) => (
            <Fragment key={id}>
              <span className={styles.computersGroupHeader}>{title}</span>
              <div className={styles.computersGroupGrid}>
                {sortBy(hosts, sortOpts).map((item) => {
                  const isBusy = !!item.client_sessions.length;
                  const isBooked = !!item.bookings.length;
                  return (
                    <div
                      key={item.id}
                      className={cx(styles.computersGroupGridItem, {
                        [styles.computersGroupGridItemBusy]: isBusy,
                      })}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          gap: 8,
                        }}
                      >
                        <span
                          className={cx(styles.computersGroupGridItemStatus, {
                            [styles.computersGroupGridItemStatusBusy]: isBusy,
                          })}
                          alt={"test"}
                        >
                          {isBusy ? "Занят" : "Свободен"}
                        </span>
                      </div>
                      <span style={{ textAlign: "end" }}>
                        {isBusy &&
                          `До ${moment(
                            item.client_sessions[0].finished_at
                          ).format(viewFormat)}`}
                      </span>
                      <span className={styles.computersGroupGridItemTitle}>
                        {item.alias}{" "}
                      </span>
                      <span></span>
                      <div>
                        {isBooked && (
                          <span
                            className={cx(
                              styles.help,
                              styles.computersGroupGridItemStatus,
                              {
                                [styles.computersGroupGridItemStatusBusy]:
                                  isBusy,
                              }
                            )}
                            title={`С ${moment(item.bookings[0].from).format(
                              viewFormat
                            )} до ${moment(item.bookings[0].to).format(
                              viewFormat
                            )}`}
                          >
                            Есть бронь
                          </span>
                        )}
                      </div>
                      <div
                        style={{ display: "flex", placeContent: "flex-end" }}
                      >
                        <button
                          className={styles.button}
                          onClick={() => setReserved(item)}
                        >
                          Забронировать
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Fragment>
          ))}
        </div>
      )}

      <Modal
        title={`Забронировать компьютер ${(reserved && reserved.alias) || ""}`}
        open={!!reserved}
        onCancel={() => setReserved()}
        footer={null}
        // footer={[
        //   <Button key="back" htmlType="button" onClick={() => setReserved()}>
        //     Отмена
        //   </Button>,
        //   <Button key="submit" htmlType="submit">
        //     Забронировать
        //   </Button>,
        // ]}
      >
        <Form
          name="reservation"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Номер телефона"
            name="phone"
            rules={[
              { required: true, message: "Поле обязательно для заполнения" },
            ]}
          >
            <InputMask mask="(999) 999-99-99" placeholder="(___) ___-__-__" />
            {/* <MaskedInput mask="***-***-**-**" name="phone" /> */}
            {/* <Input prefix="+7" /> */}
          </Form.Item>

          <div style={{ display: "flex", gap: 8 }}>
            <Form.Item
              label="Дата и время брони"
              name="dateFrom"
              rules={[
                { required: true, message: "Поле обязательно для заполнения" },
              ]}
              style={{ width: "100%" }}
            >
              <DatePicker
                showTime={{
                  hideDisabledOptions: true,
                  minuteStep: 15,
                  defaultValue: dayjs("00:00", "HH:mm"),
                }}
                format={viewFormat}
                style={{ width: "100%" }}
                showNow={false}
                disabledDate={(current) =>
                  current && current < dayjs().startOf("day")
                }
              />
            </Form.Item>

            {reserved && !!reserved.bookings.length && (
              <Popover content={popoverContent} title="Недоступное время">
                <WarningTwoTone
                  style={{
                    fontSize: "20px",
                    marginTop: 6,
                    display: "flex",
                    alignItems: "baseline",
                  }}
                />
              </Popover>
            )}
          </div>
          <Form.Item
            label="Количество часов"
            name="hours"
            rules={[
              { required: true, message: "Поле обязательно для заполнения" },
            ]}
          >
            <InputNumber
              type="number"
              min={1}
              max={24}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <div style={{ display: "flex", placeContent: "flex-end" }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading}
            >
              Забронировать
            </Button>
          </div>
        </Form>
      </Modal>
    </section>
  );
};

export default MapSection;
