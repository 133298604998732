export const NavArrow = (props) => (
    <svg
        fill="none"
        viewBox="0 0 44 44"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect height="97%" rx={21.5} stroke="#fff" width="97%" x={0.5} y={0.5} />
        <path
            // eslint-disable-next-line max-len
            d="M19.224 27.724a1.333 1.333 0 1 0 1.886 1.886l6.666-6.667c.521-.52.521-1.365 0-1.886l-6.666-6.666a1.333 1.333 0 1 0-1.886 1.885L24.948 22l-5.724 5.724Z"
            fill="#fff"
        />
    </svg>
);