import { useState } from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import styles from "./LoungeSection.module.scss";
import LoungePhoto from "../assets/images/LoungePhoto.jpg";
import cx from "classnames";
import { NavArrow } from "../components/icons";
SwiperCore.use([Navigation]);

const LoungeSection = () => {
  const [swiper, setSwiper] = useState();

  const handleNavKeyPress = (nav) => (event) => {
    if (event.key === "Enter" && swiper) {
      if (nav === "prev") {
        swiper.slidePrev();
      } else {
        swiper.slideNext();
      }
    }
  };

  return (
    <section className={styles.lounge}>
      <h2 className={styles.loungeHeader}>Лаунж</h2>
      <div className={styles.loungeText}>
        Атмосферный лаунж с PlayStation 5<br />
        2 игровые зоны с диванами и TV 55&quot; 4К. Все самые популярные игры:
        FIFA 24, NHL 23, NBA 23, UFC 4, Mortal Combat 1, GTA V, GOD OF WAR 1/2,
        UNCHARTED 4, Assassin Creed Valhalla, NFS.
        <br />
        Подписка на +400 игр от PS Plus и EA Sports
      </div>
      {/*<img  className={styles.loungePhoto} src={LoungePhoto}/>*/}
      <Swiper
        className={styles.loungeSwiper}
        onSwiper={setSwiper}
        loop
        slidesPerView={1}
        navigation={{
          prevEl: ".favorite-swiper-button-prev",
          nextEl: ".favorite-swiper-button-next",
        }}
      >
        <SwiperSlide className={styles.loungeSwiperSlide}>
          <img
            src={require("../assets/images/LoungePhoto.jpg")}
            className={styles.loungeSwiperImage}
          />
        </SwiperSlide>
        <SwiperSlide className={styles.loungeSwiperSlide}>
          <img
            src={require("../assets/images/LoungePhoto2.jpg")}
            className={styles.loungeSwiperImage}
          />
        </SwiperSlide>
        <div className={styles.nav}>
          <div className={styles.navRow}>
            <div
              className={cx(
                "favorite-swiper-button-prev",
                styles.navButton,
                styles.navButtonPrev
              )}
              onKeyPress={handleNavKeyPress("prev")}
            >
              <NavArrow />
            </div>
            <div
              className={cx(
                "favorite-swiper-button-next",
                styles.navButton,
                styles.navButtonNext
              )}
              onKeyPress={handleNavKeyPress("next")}
            >
              <NavArrow />
            </div>
          </div>
        </div>
      </Swiper>
    </section>
  );
};

export default LoungeSection;
