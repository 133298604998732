import styles from "./AboutSection.module.scss"
// todo
// import {CheckIcon} from "../assets/images/icon-check.png"
import {CheckIcon} from "../components/icons";

const AboutSection = () => {
    return (
        <section className={styles.about}>
            <div className={styles.aboutContainer}>
                <div className={styles.aboutDesc}>
                    <h1 className={styles.aboutDescHeader}>
                        Элизиум – это космический центр киберспорта
                    </h1>
                    <div className={styles.aboutDescText}>
                        Это место для тех, кто любит помериться силами с виртуальным противником и пообщаться с единомышленниками.
                        Мы создали наши клубы с учетом самых современных тенденций в игровой индустрии.
                        Новейшее оборудование позволяет погрузиться в мир виртуальных сражений и стать лучшим в ваших любимых играх: Dota 2, CS:GO, PUBG, Apex Legends, WoT, Fortnite, Warface и др.
                        Мы прилагаем максимум усилий, чтобы вам у нас было комфортно и одному, и с компанией друзей.
                        На это работает сама атмосфера клуба, удобство игровой зоны, вежливый и профессиональный персонал.
                        Мы следим за всеми игровыми новинками и тут же пополняем ими наш арсенал игр, но не забываем и о классике, поэтому вы найдете для себя любую игру по вкусу.
                    </div>
                </div>
                <div className={styles.aboutList}>
                    <div className={styles.aboutListItem}>
                        <div className={styles.aboutListBlockFirst}/>
                        <div className={styles.aboutListBlockImage}>
                            <CheckIcon/>
                        </div>
                        <div className={styles.aboutListBlockSecond}>
                            Современное железо и топовая периферия
                        </div>
                    </div>
                    <div className={styles.aboutListItem}>
                        <div className={styles.aboutListBlockFirst}/>
                        <div className={styles.aboutListBlockImage}>
                            <CheckIcon/>
                        </div>
                        <div className={styles.aboutListBlockSecond}>
                            Работаем круглосуточно 24/7
                        </div>
                    </div>
                    <div className={styles.aboutListItem}>
                        <div className={styles.aboutListBlockFirst}/>
                        <div className={styles.aboutListBlockImage}>
                            <CheckIcon/>
                        </div>
                        <div className={styles.aboutListBlockSecond}>
                            Максимальный комфорт во всем
                        </div>
                    </div>
                    <div className={styles.aboutListItem}>
                        <div className={styles.aboutListBlockFirst}/>
                        <div className={styles.aboutListBlockImage}>
                            <CheckIcon/>
                        </div>
                        <div className={styles.aboutListBlockSecond}>
                            Выгодные цены <br className={styles.aboutListBlockBr}/>на бар и снеки
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutSection;
