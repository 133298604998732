import { useState } from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import styles from "./BootcampSection.module.scss";
import cx from "classnames";
import { NavArrow } from "../components/icons";
SwiperCore.use([Navigation]);

const BootcampSection = () => {
  const [swiper, setSwiper] = useState();

  const handleNavKeyPress = (nav) => (event) => {
    if (event.key === "Enter" && swiper) {
      if (nav === "prev") {
        swiper.slidePrev();
      } else {
        swiper.slideNext();
      }
    }
  };

  return (
    <section className={styles.bootcamp} id="characterisctics">
      <h2 className={styles.bootcampHeader}>Компьютерный зал и BOOTCAMP</h2>
      <div className={styles.bootcampText}>
        <ul>
          20 игровых компьютеров:
          <li>Intel Core 11-го поколения</li>
          <li>RTX 3060, RTX 3070TI</li>
          <li>Мониторы 24’ и 27’, 144Гц и 240Гц</li>
        </ul>
        <br />
        Все самые популярные игры: Dota 2, CS:GO, WarZone 2, PUBG, Valorant,
        WoT, Left 4 Dead 2, Warface, Left 4 Dead 2, CS 1.6, League of Legends.
      </div>
      <Swiper
        className={styles.bootcampSwiper}
        onSwiper={setSwiper}
        loop
        slidesPerView={1}
        navigation={{
          prevEl: ".favorite-swiper-button-prev",
          nextEl: ".favorite-swiper-button-next",
        }}
      >
        <SwiperSlide className={styles.bootcampSwiperSlide}>
          <img
            src={require("../assets/images/BootcampPhoto5.jpg")}
            className={styles.bootcampSwiperImage}
          />
        </SwiperSlide>
        <SwiperSlide className={styles.bootcampSwiperSlide}>
          <img
            src={require("../assets/images/BootcampPhoto4.jpg")}
            className={styles.bootcampSwiperImage}
          />
        </SwiperSlide>
        <SwiperSlide className={styles.bootcampSwiperSlide}>
          <img
            src={require("../assets/images/BootcampPhoto.jpg")}
            className={styles.bootcampSwiperImage}
          />
        </SwiperSlide>
        {/* <SwiperSlide className={styles.bootcampSwiperSlide}>
          <img
            src={require("../assets/images/BootcampPhoto2.jpeg")}
            className={styles.bootcampSwiperImage}
          />
        </SwiperSlide> */}
        <div className={styles.nav}>
          <div className={styles.navRow}>
            <div
              className={cx(
                "favorite-swiper-button-prev",
                styles.navButton,
                styles.navButtonPrev
              )}
              onKeyPress={handleNavKeyPress("prev")}
            >
              <NavArrow />
            </div>
            <div
              className={cx(
                "favorite-swiper-button-next",
                styles.navButton,
                styles.navButtonNext
              )}
              onKeyPress={handleNavKeyPress("next")}
            >
              <NavArrow />
            </div>
          </div>
        </div>
      </Swiper>
    </section>
  );
};

export default BootcampSection;
