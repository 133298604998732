import "./App.css";
import Header from "./components/Header/Header";
import AboutSection from "./sections/AboutSection";
import PricesSection from "./sections/PricesSection";
import MapSection from "./sections/MapSection";
// import FooterSection from "./sections/FooterSection";
import LoungeSection from "./sections/LoungeSection";
import BootcampSection from "./sections/BootcampSection";

function App() {
  return (
    <>
      <Header />
      <AboutSection />
      {/*<PhotoSection/>*/}
      <BootcampSection />
      <LoungeSection />
      {/*<Characteristics/>*/}
      <PricesSection />
      {/* <MapSection /> */}
      {/* <FooterSection /> */}
    </>
  );
}

export default App;
