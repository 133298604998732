import styles from "./PricesSection.module.scss";
import Logo from "../assets/logo/logo.png";

const PricesSection = () => {
  return (
    <section className={styles.prices} id="prices">
      <div className={styles.pricesWrap}>
        <div className={styles.pricesContainer}>
          <h2 className={styles.pricesHeader}>Цены</h2>
          <table className={styles.pricesTable}>
            <thead>
              <tr>
                <th colSpan={2} className={styles.pricesTableLogo}>
                  <img alt="logo" src={Logo} />
                </th>
                <th colSpan={2} className={styles.orange}>
                  STANDARD
                </th>
                <th colSpan={2} className={styles.orange}>
                  STANDARD+
                </th>
                <th colSpan={2} className={styles.orange}>
                  BOOTCAMP
                </th>
                <th className={styles.orange}>PS5</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  rowSpan={2}
                  style={{ width: "14%" }}
                  className={styles.orange}
                >
                  1 ЧАС
                </td>
                <td
                  className={styles.noPadding}
                  style={{ borderBottom: "0" }}
                ></td>
                <td className={styles.noPadding}>Будни</td>
                <td className={styles.noPadding}>Вых-е</td>
                <td className={styles.noPadding}>Будни</td>
                <td className={styles.noPadding}>Вых-е</td>
                <td className={styles.noPadding}>Будни</td>
                <td className={styles.noPadding}>Вых-е</td>
                <td className={styles.noPadding}>Все дни</td>
              </tr>
              <tr>
                <td style={{ borderTop: "0" }}>
                  <span>c 8:00 до 15:00</span>
                  <br></br>
                  <span>с 15:00 до 8:00</span>
                </td>
                <td>
                  <span>90 &#x20BD;</span>
                  <br />
                  <span>120 &#x20BD;</span>
                </td>
                <td>
                  <span>120 &#x20BD;</span>
                  <br />
                  <span>140 &#x20BD;</span>
                </td>
                <td>
                  <span>100 &#x20BD;</span>
                  <br />
                  <span>130 &#x20BD;</span>
                </td>
                <td>
                  <span>130 &#x20BD;</span>
                  <br />
                  <span>150 &#x20BD;</span>
                </td>
                <td>
                  <span>140 &#x20BD;</span>
                  <br />
                  <span>160 &#x20BD;</span>
                </td>
                <td>
                  <span>160 &#x20BD;</span>
                  <br />
                  <span>180 &#x20BD;</span>
                </td>
                <td>
                  <span>199 &#x20BD;</span>
                  <br />
                  <span>240 &#x20BD;</span>
                </td>
              </tr>
              <tr>
                <td className={styles.orange}>ПАКЕТ</td>
                <td>
                  <span>УТРО 2ч. с 8:00 до 13:00</span>
                  <br />
                  <span>3 ЧАСА день</span>
                  <br />
                  <span>3 ЧАСА вечер</span>
                  <br />
                  <span>5 ЧАСОВ день</span>
                  <br />
                  <span>5 ЧАСОВ вечер</span>
                  <br />
                  <span>НОЧЬ с 22:00 до 8:00</span>
                  <br />
                  <span>СУТКИ (24 часа)</span>
                </td>
                <td>
                  <span>100 &#x20BD;</span>
                  <br />
                  <span>250 &#x20BD;</span>
                  <br />
                  <span>300 &#x20BD;</span>
                  <br />
                  <span>400 &#x20BD;</span>
                  <br />
                  <span>450 &#x20BD;</span>
                  <br />
                  <span>450 &#x20BD;</span>
                  <br />
                  <span>1300 &#x20BD;</span>
                </td>
                <td>
                  <span>-</span>
                  <br />
                  <span>300 &#x20BD;</span>
                  <br />
                  <span>350 &#x20BD;</span>
                  <br />
                  <span>500 &#x20BD;</span>
                  <br />
                  <span>550 &#x20BD;</span>
                  <br />
                  <span>550 &#x20BD;</span>
                  <br />
                  <span>1300 &#x20BD;</span>
                </td>
                <td>
                  <span>110 &#x20BD;</span>
                  <br />
                  <span>280 &#x20BD;</span>
                  <br />
                  <span>330 &#x20BD;</span>
                  <br />
                  <span>450 &#x20BD;</span>
                  <br />
                  <span>500 &#x20BD;</span>
                  <br />
                  <span>500 &#x20BD;</span>
                  <br />
                  <span>1400 &#x20BD;</span>
                </td>
                <td>
                  <span>-</span>
                  <br />
                  <span>330 &#x20BD;</span>
                  <br />
                  <span>380 &#x20BD;</span>
                  <br />
                  <span>550 &#x20BD;</span>
                  <br />
                  <span>600 &#x20BD;</span>
                  <br />
                  <span>600 &#x20BD;</span>
                  <br />
                  <span>1400 &#x20BD;</span>
                </td>
                <td>
                  <span>-</span>
                  <br />
                  <span>350 &#x20BD;</span>
                  <br />
                  <span>400 &#x20BD;</span>
                  <br />
                  <span>500 &#x20BD;</span>
                  <br />
                  <span>550 &#x20BD;</span>
                  <br />
                  <span>550 &#x20BD;</span>
                  <br />
                  <span>1600 &#x20BD;</span>
                </td>
                <td>
                  <span>-</span>
                  <br />
                  <span>400 &#x20BD;</span>
                  <br />
                  <span>450 &#x20BD;</span>
                  <br />
                  <span>600 &#x20BD;</span>
                  <br />
                  <span>650 &#x20BD;</span>
                  <br />
                  <span>650 &#x20BD;</span>
                  <br />
                  <span>1600 &#x20BD;</span>
                </td>
                <td>
                  <span>-</span>
                  <br />
                  <span>600 &#x20BD;</span>
                  <br />
                  <span>600 &#x20BD;</span>
                  <br />
                  <span>-</span>
                  <br />
                  <span>-</span>
                  <br />
                  <span>999 &#x20BD;</span>
                  <br />
                  <span>-</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div className={styles.pricesTime}>
            Время продажи пакетов: утро до 11:00, 3ч/д и 5ч/д до 15:00, 3ч/в и
            5ч/в до 21:00
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricesSection;
