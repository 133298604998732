import * as React from "react"

export const VKIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        width={props.width}
        height={props.height}
         {...props}>
        <path
            d="M11.016 17C4.26 17 .16 12.116 0 4h3.422c.107 5.962 2.71 8.489 4.705 9.008V4h3.279v5.145c1.925-.222 3.94-2.562 4.617-5.145h3.228c-.517 3.176-2.71 5.516-4.26 6.481 1.551.781 4.046 2.824 5.009 6.519h-3.547c-.75-2.47-2.585-4.383-5.045-4.643V17h-.392Z"
            fill="white"
        />
    </svg>
)
