import React, { useState } from "react";
import styles from "./Header.module.scss";
import LogoTextImageSrc from "../../assets/logo/Full_logo.png";
import { GeolocationIcon } from "../icons";
import { HashLink } from "react-router-hash-link";
import { TelegramIcon, VKIcon, WhatsAppIcon } from "../../components/icons";
import Modal from "react-modal";
Modal.setAppElement("#root");

const locationUri =
  "https://yandex.ru/maps/org/elizium/239842293040/?ll=37.706786%2C55.930199&z=10";

const Header = () => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const onClick = () => {
    window.open(locationUri, "_blank");
  };

  return (
    <header className={styles.header}>
      <div className={styles.headerWrap}>
        <div className={styles.headerLogoContainer}>
          <img
            className={styles.headerLogo}
            src={LogoTextImageSrc}
            alt={"Ping"}
          />
        </div>
        <nav className={styles.headerNavigation}>
          <div className={styles.headerNavigationList}>
            <HashLink smooth to="#characterisctics">
              О КЛУБЕ
            </HashLink>
            <HashLink smooth to="/#prices">
              ЦЕНЫ
            </HashLink>
            <HashLink smooth to="/#reservation">
              БРОНИРОВАНИЕ
            </HashLink>
          </div>
        </nav>
        <div className={styles.headerBurger} onClick={() => setIsOpen(true)}>
          <span />
          <span />
          <span />
        </div>
        <div className={styles.lineBreak} />
        <div className={styles.headerAddress}>
          <div className={styles.headerSocial}>
            <div className={styles.headerSocialHeader}>Работаем 24/7</div>
            <div className={styles.headerSocialIcons}>
              <a
                href={"https://vk.com/public216580557"}
                target="_blank"
                rel="noreferrer"
                className={styles.headerSocialIcon}
              >
                <VKIcon width={16} />
              </a>
              <a
                href={"https://wa.me/79164381022"}
                target="_blank"
                rel="noreferrer"
                className={styles.headerSocialIcon}
              >
                <WhatsAppIcon width={24} />
              </a>
              <a
                href={"https://t.me/Eliziumarena"}
                target="_blank"
                rel="noreferrer"
                className={styles.headerSocialIcon}
              >
                <TelegramIcon width={24} />
              </a>
              {/*<a*/}
              {/*    href={"https://instagram.com/elizium_arena?igshid=ZDdkNTZiNTM="}*/}
              {/*    target="_blank"*/}
              {/*    className={styles.headerSocialIcon}>*/}
              {/*    <InstIcon width={40}/>*/}
              {/*</a>*/}
            </div>
          </div>
          <div className={styles.headerAddressWrap}>
            <button onClick={onClick}>
              <GeolocationIcon
                width={32}
                height={32}
                className={styles.headerAddressIcon}
              />
            </button>
            <div className={styles.headerAddressText}>
              <span>
                Мытищи, проспект Астрахова 9 <br /> +7 (916) 438-10-22
              </span>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setIsOpen(false)}
          className={styles.modal}
          overlayClassName={styles.overlay}
        >
          <div className={styles.headerNavigationList}>
            <HashLink
              smooth
              to="#characterisctics"
              onClick={() => setIsOpen(false)}
            >
              О КЛУБЕ
            </HashLink>
            <HashLink smooth to="/#prices" onClick={() => setIsOpen(false)}>
              ЦЕНЫ
            </HashLink>
            <HashLink
              smooth
              to="/#reservation"
              onClick={() => setIsOpen(false)}
            >
              БРОНИРОВАНИЕ
            </HashLink>
          </div>
        </Modal>
      </div>
    </header>
  );
};
export default Header;
