import * as React from "react"

export const WhatsAppIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 128 128"
        width={props.width}
        height={props.height}
        {...props}>
            <circle cx={64} cy={64} fill="#25D366" r={64} />
            <path
                d="M92.346 35.49c-7.522-7.53-17.523-11.678-28.179-11.683-21.954 0-39.826 17.868-39.833 39.831a39.743 39.743 0 0 0 5.316 19.913L24 104.193l21.115-5.538a39.837 39.837 0 0 0 19.036 4.847h.017c21.954 0 39.823-17.871 39.832-39.833.005-10.642-4.136-20.65-11.654-28.179M64.168 96.774h-.013a33.062 33.062 0 0 1-16.853-4.614l-1.209-.718-12.53 3.287 3.343-12.216-.787-1.256a32.998 32.998 0 0 1-5.062-17.619c.006-18.253 14.859-33.104 33.121-33.104 8.844.002 17.155 3.451 23.407 9.71 6.251 6.258 9.691 14.575 9.689 23.422-.006 18.256-14.859 33.108-33.106 33.108m18.16-24.795c-.996-.499-5.889-2.904-6.802-3.239-.913-.332-1.574-.497-2.238.499s-2.571 3.239-3.153 3.903c-.58.664-1.16.748-2.156.249s-4.202-1.549-8.001-4.941c-2.96-2.637-4.958-5.899-5.538-6.895s-.062-1.533.437-2.03c.448-.446.996-1.162 1.493-1.744.497-.582.663-.997.995-1.66.332-.664.167-1.245-.083-1.743-.25-.499-2.24-5.398-3.068-7.391-.809-1.941-1.629-1.678-2.239-1.708a41.082 41.082 0 0 0-1.908-.036c-.663 0-1.742.249-2.654 1.246-.911.996-3.483 3.403-3.483 8.304 0 4.898 3.566 9.632 4.064 10.295.498.663 7.018 10.718 17.002 15.029a57.94 57.94 0 0 0 5.674 2.097c2.384.759 4.554.65 6.27.394 1.912-.285 5.888-2.407 6.719-4.732.829-2.324.829-4.316.578-4.732-.251-.417-.915-.666-1.909-1.165"
                fill="#FFF"
            />
    </svg>
)
