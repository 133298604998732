import * as React from "react"

export const TelegramIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        width={props.width}
        height={props.height}
        {...props}>
        <circle cx={256} cy={256} fill="#08C" r={256} />
        <path
            d="M246.4 332.1c-12.3 11.9-24.4 23.7-36.5 35.5-4.2 4.1-8.9 6.4-15 6.1-4.1-.2-6.4-2-7.7-5.9-9.2-28.6-18.6-57.2-27.8-85.9-.9-2.8-2.2-4.1-5-5-21.7-6.6-43.5-13.4-65.1-20.3-3.3-1.1-6.7-2.4-9.6-4.4-4.5-3-5.1-7.9-1.1-11.5 3.7-3.3 8.1-6.1 12.7-7.9 26.6-10.5 53.3-20.7 80-31 67.7-26.1 135.4-52.3 203.1-78.4 12.9-5 22.8 2 21.4 16-.9 8.9-3.2 17.7-5 26.5-14.7 69.4-29.4 138.9-44.2 208.3-3.5 16.5-15.1 20.8-28.6 10.8-22.7-16.7-45.4-33.5-68.1-50.3-1.1-.9-2.2-1.7-3.5-2.6zm-51 21.1c.3-.1.5-.1.8-.2.1-.7.3-1.3.4-1.9 1.5-15.7 3-31.5 4.3-47.2.3-3.5 1.5-6 4.1-8.4 20.9-18.7 41.8-37.6 62.6-56.4 23.1-20.8 46.2-41.6 69.2-62.5 1.4-1.3 2-3.5 3-5.3-2.2-.2-4.5-1.1-6.5-.6-2.7.7-5.2 2.3-7.6 3.8-50.9 32.1-101.9 64.2-152.8 96.3-2.9 1.8-3.4 3.3-2.3 6.5 3.8 10.8 7.2 21.7 10.7 32.6 4.7 14.4 9.4 28.9 14.1 43.3z"
            fill="#FFF"
        />
    </svg>
)
